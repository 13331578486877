/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { Link } from 'react-scroll'

import 'bootstrap/dist/css/bootstrap.min.css'

import './layout.css'

import './main.css'

const upArrow = require('../images/up_arrow.png')

const Layout = ({ children }: { children: any }) => {
    const data = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `)

    return (
        <>
            {children}
            <div className="top-scroll-bottom">
                <Link
                    to={'header_image_with_text'}
                    smooth={true}
                    duration={1000}
                >
                    <img src={upArrow} />
                    <div>TOP</div>
                </Link>
            </div>
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
